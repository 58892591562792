<template>
  <div class="container">
    <div class="row mb-3">
      <div class="col-12 d-flex">
        <select v-model="selectedEnterprise" name="enterprise" id="enterprise" class="form-control">
          <option value="" disabled>Seleccione una empresa</option>
          <option v-for="enterprise in availableEnterprises" :key="enterprise.id" :value="enterprise.id">
            {{ enterprise.business_name }}
          </option>
        </select>
        <button type="button" @click="addEnterprise" class="btn btn-primary ml-2">Agregar</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-hover tablesaw tablesaw-stack" data-tablesaw-mode="stack">
          <thead>
          <tr>
            <th>Empresa</th>
            <th>Porcentaje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(billing_enterprise, index) in billing_enterprises_attributes" :key="index">
            <td class="col-md-6">
              <p>{{ billing_enterprise.enterprise.business_name }}</p>
              <input type="hidden"
                     :name="'stage[billing_enterprises_attributes][' + index + '][id]'"
                     :value="billing_enterprise.id">
              <input type="hidden"
                     :name="'stage[billing_enterprises_attributes][' + index + '][enterprise_id]'"
                     :value="billing_enterprise.enterprise.id">
            </td>
            <td class="col-md-6">
              <div class="input-group">
                <input min="0"
                       step="0.01"
                       max="100"
                       v-model="billing_enterprises_attributes[index].percentage"
                       class="form-control text-right"
                       type="number"
                       :name="'stage[billing_enterprises_attributes][' + index + '][percentage]'"
                       :id="'stage_billing_enterprises_percentage' + index"
                       required>
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'billing-enterprises',
  props: [
    'enterprises',
    'billing_enterprises'
  ],
  data() {
    return {
      billing_enterprises_attributes: [],
      selectedEnterprise: null,
      availableEnterprises: [...this.enterprises],
    }
  },
  methods: {
    addEnterprise() {
      if (!this.selectedEnterprise) return;
      const enterprise = this.availableEnterprises.find(ent => ent.id === this.selectedEnterprise);
      this.billing_enterprises_attributes.push({ enterprise, percentage: 0 });
      this.availableEnterprises = this.availableEnterprises.filter(ent => ent.id !== this.selectedEnterprise);
      this.selectedEnterprise = null;
    }
  },
  mounted() {
    if (this.billing_enterprises && this.billing_enterprises.length > 0) {
      this.billing_enterprises_attributes = [...this.billing_enterprises];
      this.availableEnterprises = this.availableEnterprises.filter(ent => {
        return !this.billing_enterprises_attributes.some(billing_enterprise => billing_enterprise.enterprise.id === ent.id);
      });
    }
    $('#enterprise').on('change', event => this.selectedEnterprise = Number(event.target.value));
  }
}
</script>
