<template>
  <tr>
    <td>{{ email.date }}</td>
    <td>{{ email.event }}</td>
    <td>{{ email.subject }}</td>
    <td>{{ email.delivery_status }}</td>
  </tr>
</template>
<script>
  export default {
    props: ['email'],
    methods: {},
  }
</script>
<style scoped>
</style>
