<template>
  <div class="client-card my-2 py-2 px-1 rounded border bg-light position-relative" :data-client-id="client.id" :data-current-client-status-id="client.client_status_id">
    <div class="text-dark d-block text-left">
      <div class="card-main-data">
        <p class="m-0"><strong>{{ client.label }}</strong></p>
        <p class="m-0">Correo: {{ client.email }}</p>
        <p class="m-0">Asesor: {{ client.sales_executive_label }}</p>
        <p class="m-0">Origen: {{ client.lead_source_name }}</p>
        <p class="m-0">Fecha de asignación: {{ client.assignation_date }}</p>
      </div>
      <div class="card-main-data text-center">
        <a v-if="client.can_create_app_user"
           :href="client.create_app_user_url"
           data-method="post"
           data-original-title="Crear usuario del app"
           rel="tooltip nofollow"
           title="Crear usuario del app">
          <i aria-hidden="true" class="fa fa-mobile fa-lg"></i>
        </a>
        <a v-if="client.can_recovery_password_app_user"
           :href="client.recovery_password_app_user_url"
           data-toggle="tooltip"
           data-remote="true"
           data-method="patch"
           data-original-title="Enviar correo de recuperación de contraseña"
           rel="nofollow"
           title="Enviar correo de recuperación de contraseña">
          <i class="fa fa-envelope-o"></i>
        </a>
        <a v-if="client.can_quote"
           :href="client.quote_url"
           rel="tooltip"
           title="Cotizar"
           data-original-title="Cotizar">
          <i aria-hidden="true" class="fa fa-arrow-right"></i>
        </a>
        <a v-if="client.can_read_client"
           :href="client.read_client_url"
           rel="tooltip"
           title="Ver"
           data-original-title="Ver">
          <i aria-hidden="true" class="fa fa-eye"></i>
        </a>
        <a v-if="client.can_update_client"
           :href="client.edit_client_url"
           rel="tooltip"
           title="Editar"
           data-original-title="Editar">
          <i aria-hidden="true" class="fa fa-pencil-square-o"></i>
        </a>
        <a v-if="client.can_destroy_client"
           :href="client.destroy_client_url"
           data-toggle="tooltip"
           data-confirm="¿Estás seguro?"
           data-method="delete"
           data-original-title="Eliminar"
           rel="nofollow"
           title="Eliminar">
          <i class="fa fa-trash"></i>
        </a>
        <a v-if="client.can_resend_confirmation_email"
           :href="client.resend_confirmation_email_url"
           data-toggle="tooltip"
           data-method="patch"
           data-remote="true"
           data-original-title="Enviar correo de confirmación"
           rel="nofollow"
           title="Enviar correo de confirmación">
          <i class="fa fa-envelope-o"></i>
        </a>
        <a :href="client.change_client_status_url"
           data-toggle="modal"
           data-target="#modal-window"
           data-remote="true"
           rel="tooltip"
           title="Modificar estado"
           data-original-title="Modificar estado">
          <img v-bind:src="share_icon" width="14" height="14" alt="Modificar estatus">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import share_icon from 'images/icons/share.png';

export default {
  props: ['client'],
  data() {
    return {
      share_icon
    }
  },
  methods: {}
}
</script>
