export default {
  methods: {
    formatMoney(number, currencyTag, currencyCode) {
      if (number == null) {
        return '';
      }
      if (typeof number == 'string') {
        number = parseFloat(number);
      }

      return number.toLocaleString(currencyTag, {
        style: 'currency',
        currency: currencyCode,
      });
    },
  },
};
