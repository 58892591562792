<template>
  <section v-if="local_mandatory_additional_concepts.length > 0 || local_optional_additional_concepts.length > 0">
    <div class="row" v-if="!is_public_quote">
      <div class="col-md-1 text-center py-3">
        <img v-bind:src="quotation_down_payment" class="img-fluid">
      </div>
      <div class="col-md-11">
        <div class="d-flex flex-wrap justify-content-between">
          <h6 class="text-left vertical-align m-0 py-3">Adicionales</h6>
        </div>
        <div class="row mb-3">
          <div class="col-4 pr-0">
            <hr class="marker-bar-green">
          </div>
          <div class="col-8 pl-0">
            <hr class="marker-bar-gray">
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.local_mandatory_additional_concepts.length > 0">
      <div class="form-group" :class="{ 'col-sm-12 col-md-4': !is_public_quote, 'col-12': is_public_quote }">
        <label for="mandatory_additional_concept_id" v-if="!is_public_quote">Adicionales obligatorios</label>
        <select name="mandatory_additional_concept_id"
                id="mandatory_additional_concept_id"
                class="form-control"
                ref="mandatory_additional_concept_id_select"
                v-select2
                @changeSelect="setMandatoryAdditionalConcept($event.target.value)"
                v-if="local_mandatory_additional_concepts.length > 1">
          <option value="">Seleccione un adicional</option>
          <option v-for="additional_concept in local_mandatory_additional_concepts"
                  :value="additional_concept.id"
                  :key="additional_concept.id">
            {{ additional_concept.name }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="is_mandatory_selected">
      <div class="row">
        <div class="form-group col-sm-12 col-md-4">
          <label for="mandatory_additional_concept_name" class="font-weight-bold">{{ is_public_quote ? 'Concepto' : 'Adicional' }}</label>
          <p>{{ onlyMandatoryAdditionalConcept.name }}</p>
          <input type="hidden" name="additional_concepts[0][id]" :value="onlyMandatoryAdditionalConcept.id">
          <input type="hidden" name="additional_concepts[0][name]" :value="onlyMandatoryAdditionalConcept.name">
        </div>
        <template v-if="onlyMandatoryAdditionalConcept.fixed_payment_periods && onlyMandatoryAdditionalConcept.fixed_payment_periods.length > 0 &&!onlyMandatoryAdditionalConcept.add_to_lot_price">
          <div class="form-group col-sm-12 col-md-4">
            <label for="mandatory_additional_concept_fixed_payment_period" class="font-weight-bold">Mensualidades</label>
            <select name="additional_concepts[0][fixed_payment_period]"
                    id="mandatory_additional_concept_fixed_payment_period"
                    class="form-control"
                    ref="mandatory_additional_concept_fixed_payment_period_select"
                    v-select2
                    @changeSelect="submitParentForm()">
              <option v-for="fixed_payment_period in onlyMandatoryAdditionalConcept.fixed_payment_periods"
                      :value="fixed_payment_period.total_payments"
                      :key="fixed_payment_period.id">
                {{ fixed_payment_period.total_payments }}
              </option>
            </select>
          </div>
        </template>
        <div class="form-group col-sm-12 col-md-4">
          <label for="mandatory_additional_concept_amount" class="font-weight-bold">Monto</label>
          <template v-if="!is_public_quote && onlyMandatoryAdditionalConcept.amount_type === 'variable'">
            <div class="vertical-align">
              <div class="input-group">
                <input type="number"
                        name="additional_concepts[0][amount]"
                        id="mandatory_additional_concept_amount"
                        :value="onlyMandatoryAdditionalConcept.amount"
                        class="form-control"
                        @change="submitParentForm()">
                <div class="input-group-append">
                  <span class="input-group-text">$</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p>{{ numberToCurrency(onlyMandatoryAdditionalConcept.amount, currency_tag, currency_code) }}</p>
            <input type="hidden" name="additional_concepts[0][amount]" :value="onlyMandatoryAdditionalConcept.amount">
          </template>
        </div>
      </div>
    </template>

    <div class="row" v-if="this.local_optional_additional_concepts.length > 0">
      <div class="form-group" :class="{ 'col-sm-12 col-md-4': !is_public_quote, 'col-12': is_public_quote }">
        <label for="optional_additional_concept_ids">{{ is_public_quote ? 'Opcionales' : 'Adicionales opcionales' }}</label>
        <select name="optional_additional_concept_ids"
                id="optional_additional_concept_ids"
                class="form-control not-select2"
                v-select2
                ref="optional_additional_concept_ids_select"
                @changeSelect="setOptionalAdditionalConcepts($event.target)"
                multiple>
          <option v-for="additional_concept in local_optional_additional_concepts" :value="additional_concept.id" :key="additional_concept.id">
            {{ additional_concept.name }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="onlyOptionalAdditionalConcepts && onlyOptionalAdditionalConcepts.length > 0">
      <div class="row" v-for="(optional_additional_concept, index) in onlyOptionalAdditionalConcepts" :key="optional_additional_concept.id">
        <div class="form-group col-sm-12 col-md-4">
          <label for="optional_additional_concept_name" class="font-weight-bold">Adicional</label>
          <p>{{ optional_additional_concept.name }}</p>
          <input type="hidden" :name="'additional_concepts['+(index+1)+'][id]'" :value="optional_additional_concept.id">
          <input type="hidden" :name="'additional_concepts['+(index+1)+'][name]'" :value="optional_additional_concept.name">
        </div>
        <template v-if="optional_additional_concept.fixed_payment_periods && optional_additional_concept.fixed_payment_periods.length > 0 && !optional_additional_concept.add_to_lot_price">
          <div class="form-group col-sm-12 col-md-4">
            <label for="optional_additional_concept_fixed_payment_period" class="font-weight-bold">Mensualidades</label>
            <select :name="'additional_concepts['+(index+1)+'][fixed_payment_period]'"
                    :ref="'optional_additional_concept_fixed_payment_period_'+optional_additional_concept.id"
                    :id="'optional_additional_concept_fixed_payment_period_'+(index+1)"
                    class="form-control"
                    v-select2
                    @changeSelect="submitParentForm()">
              <option v-for="fixed_payment_period in optional_additional_concept.fixed_payment_periods" :value="fixed_payment_period.total_payments" :key="fixed_payment_period.id">
                {{ fixed_payment_period.total_payments }}
              </option>
            </select>
          </div>
        </template>
        <div class="form-group col-sm-12 col-md-4">
          <label for="optional_additional_concept_amount" class="font-weight-bold">Monto</label>
          <template v-if="!is_public_quote && optional_additional_concept.amount_type === 'variable'">
            <div class="vertical-align">
              <div class="input-group">
                <input type="number"
                        :name="'additional_concepts['+(index+1)+'][amount]'"
                        id="optional_additional_concept_amount"
                        :value="optional_additional_concept.amount"
                        class="form-control"
                        @change="submitParentForm()">
                <div class="input-group-append">
                  <span class="input-group-text">$</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p>{{ numberToCurrency(optional_additional_concept.amount, currency_tag, currency_code) }}</p>
            <input type="hidden" :name="'additional_concepts['+(index+1)+'][amount]'" :value="optional_additional_concept.amount">
          </template>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import quotation_down_payment from 'images/icons/quotation-down-payment.png';
import {initSelect2ForVue, numberToCurrency} from '../packs/payment-mixins';

export default {
  name: 'multiple-additional-concepts',
  props: {
    mandatory_additional_concepts: {
      type: Array,
      required: true
    },
    optional_additional_concepts: {
      type: Array,
      required: true
    },
    additional_concepts_selected: {
      type: Array
    },
    parent_form: {
      type: String
    },
    total_payments: {
      type: Number,
      default: 1
    },
    is_public_quote: {
      type: Boolean,
      default: false
    },
    currency_tag: {
      type: String,
      required: true
    },
    currency_code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      quotation_down_payment,
      additional_concepts: [],
      is_mandatory_selected: false,
      local_total_payments: this.total_payments,
      local_mandatory_additional_concepts: this.deepCopy(this.mandatory_additional_concepts),
      local_optional_additional_concepts: this.deepCopy(this.optional_additional_concepts),
      local_additional_concepts_selected: this.deepCopy(this.additional_concepts_selected || [])
    }
  },
  methods: {
    numberToCurrency,
    submitParentForm() {
      this.createRestoreInput();
      $(`#${ this.parent_form }`).submit();
      this.removeRestoreInput();
    },
    setMandatoryAdditionalConcept(additional_concept_id) {
      const index_selected = this.additional_concepts.findIndex(ac => ac.is_mandatory);
      if (index_selected !== -1) {
        this.additional_concepts.splice(index_selected, 1);
      }

      if (additional_concept_id) {
        const additional_concept = this.local_mandatory_additional_concepts.find(ac => ac.id === Number(additional_concept_id));
        this.additional_concepts.unshift(additional_concept);
        this.is_mandatory_selected = true;
      } else {
        this.local_mandatory_additional_concepts.forEach(additional_concept => {
          const index = this.additional_concepts.findIndex(ac => ac.id === additional_concept.id);
          if (index !== -1) {
            this.additional_concepts.splice(index, 1);
          }
        });
        this.is_mandatory_selected = false;
      }

      this.$nextTick(() => {
        this.submitParentForm();
      });
    },
    setOptionalAdditionalConcepts(target) {
      let selected = $(target).val();
      if (selected && selected.length > 0) {
        selected = selected.map(a => Number(a));

        const optional_selected = this.additional_concepts.filter(ac => ac.is_optional).map(ac => ac.id);
        const diff = optional_selected.filter(a => !selected.includes(a));
        if (diff && diff.length) {
          diff.forEach(id_to_delete => {
            const index_to_delete = this.additional_concepts.findIndex(ac => ac.id === id_to_delete);
            if (index_to_delete !== -1) {
              this.additional_concepts.splice(index_to_delete, 1);
            }
          });
        }

        selected.forEach(additional_concept_id => {
          const additional_concept = this.local_optional_additional_concepts.find(
            additional_concept => additional_concept.id === additional_concept_id
          );
          const exists = this.additional_concepts.some(ac => ac.id === additional_concept.id);
          if (!exists) {
            this.additional_concepts.push(additional_concept);
          }
        });
      } else {
        this.local_optional_additional_concepts.forEach(additional_concept => {
          const index = this.additional_concepts.findIndex(ac => ac.id === additional_concept.id);
          if (index !== -1) {
            this.additional_concepts.splice(index, 1);
          }
        });
      }

      this.$nextTick(() => {
        this.submitParentForm();
      });
    },
    createRestoreInput() {
      const restoreInput = document.createElement('input');
      restoreInput.type = 'hidden';
      restoreInput.name = 'restore';
      restoreInput.value = 'true';
      $(`#${this.parent_form}`).append(restoreInput);
    },
    removeRestoreInput() {
      $(`#${this.parent_form}`).remove(restoreInput);
    },
    setAdditionalConceptsSelected(data) {
      data.forEach((selected) => {
        const exists_in_mandatory = this.local_mandatory_additional_concepts.find(lmac => lmac.id === parseInt(selected.id));
        if (exists_in_mandatory) {
          this.additional_concepts.unshift(exists_in_mandatory);
          if (this.local_mandatory_additional_concepts.length > 1 && this.$refs.mandatory_additional_concept_id_select) {
            this.$refs.mandatory_additional_concept_id_select.value = exists_in_mandatory.id;
          }
          this.is_mandatory_selected = true;
        } else {
          const exists_in_optional = this.local_optional_additional_concepts.find(loac => loac.id === parseInt(selected.id));
          if (exists_in_optional) {
            this.additional_concepts.push(exists_in_optional);
          }
        }
      });

      this.$nextTick(() => {
        const optional_additional_concept_ids = this.additional_concepts.filter(ac => ac.is_optional).map(ac => ac.id) || [];
        for (let option of this.$refs.optional_additional_concept_ids_select.options) {
          option.selected = optional_additional_concept_ids.includes(parseInt(option.value));
        }

        this.updateAdditionalConceptsSelected(data);

        initSelect2ForVue(document.getElementById('mandatory_additional_concept_id'));
        initSelect2ForVue(document.getElementById('mandatory_additional_concept_fixed_payment_period'));
        initSelect2ForVue(document.getElementById('optional_additional_concept_ids'));
        document.querySelectorAll('[id^="optional_additional_concept_fixed_payment_period_"]').forEach(element => {
          initSelect2ForVue(element);
        });
      });
    },
    updateTotalPayments(data) {
      if (!data) {
        return;
      }
      this.local_total_payments = data;
      this.updateFixedPaymentPeriodsMAC();
      this.updateFixedPaymentPeriodsOAC();
    },
    updateAdditionalConceptsSelected(data) {
      if (!data || data && data.length === 0) {
        return;
      }

      data.forEach(additional_concept_selected => {
        const additional_concept = this.additional_concepts.find(
            additional_concept => additional_concept.id === parseInt(additional_concept_selected.id)
        );
        if (additional_concept) {
          if (!isNaN(additional_concept_selected.amount)) {
            additional_concept.amount = parseFloat(additional_concept_selected.amount);
          }
          if (!isNaN(additional_concept_selected.fixed_payment_period) && additional_concept.is_mandatory) {
            this.$refs.mandatory_additional_concept_fixed_payment_period_select.value = additional_concept_selected.fixed_payment_period;
          }
          if (!isNaN(additional_concept_selected.fixed_payment_period) && additional_concept.is_optional) {
            this.$refs[`optional_additional_concept_fixed_payment_period_${ additional_concept.id }`][0].value = additional_concept_selected.fixed_payment_period;
          }
        }
      });
    },
    updateFixedPaymentPeriodsMAC() {
      this.local_mandatory_additional_concepts.forEach(additional_concept => {
        const original_additional_concept = this.deepCopy(additional_concept);
        additional_concept.is_mandatory = true;
        additional_concept.fixed_payment_periods = original_additional_concept.fixed_payment_periods.filter(fixed_payment_period => {
          return fixed_payment_period.total_payments <= this.local_total_payments;
        });
      });
    },
    updateFixedPaymentPeriodsOAC() {
      this.local_optional_additional_concepts.forEach(additional_concept => {
        const original_additional_concept = this.deepCopy(additional_concept);
        additional_concept.is_optional = true;
        additional_concept.fixed_payment_periods = original_additional_concept.fixed_payment_periods.filter(fixed_payment_period => {
          return fixed_payment_period.total_payments <= this.local_total_payments;
        });
      });
    },
    changeMandatoryAdditionalConcepts(mandatory_additional_concepts) {
      this.local_mandatory_additional_concepts = this.deepCopy(mandatory_additional_concepts);

      if (this.local_mandatory_additional_concepts.length === 1) {
        this.setMandatoryAdditionalConcept(this.local_mandatory_additional_concepts[0].id);
      } else {
        this.is_mandatory_selected = false;
        this.additional_concepts = [];
      }

      this.updateFixedPaymentPeriodsMAC();
      this.$nextTick(() => this.initSelect2());
    },
    changeOptionalAdditionalConcepts(optional_additional_concepts) {
      this.local_optional_additional_concepts = this.deepCopy(optional_additional_concepts);
      this.updateFixedPaymentPeriodsOAC();
      this.$nextTick(() => this.initSelect2());
    },
    deepCopy(data) {
      return JSON.parse(JSON.stringify(data));
    },
    initSelect2() {
      initSelect2ForVue(document.getElementById('mandatory_additional_concept_id'));
      initSelect2ForVue(document.getElementById('mandatory_additional_concept_fixed_payment_period'));
      initSelect2ForVue(document.getElementById('optional_additional_concept_ids'));
    }
  },
  computed: {
    onlyMandatoryAdditionalConcept() {
      const index = this.additional_concepts.findIndex(ac => ac.is_mandatory);
      return index !== -1 ? this.additional_concepts[index] : {};
    },
    onlyOptionalAdditionalConcepts() {
      return this.additional_concepts.filter(ac => ac.is_optional);
    }
  },
  mounted() {
    if (this.local_additional_concepts_selected.length > 0) {
      this.setAdditionalConceptsSelected(this.local_additional_concepts_selected);
    }
    if (this.local_additional_concepts_selected.length === 0 &&
        this.local_mandatory_additional_concepts.length === 1) {
      this.setMandatoryAdditionalConcept(this.local_mandatory_additional_concepts[0].id);
    }

    this.updateFixedPaymentPeriodsMAC();
    this.updateFixedPaymentPeriodsOAC();

    this.$nextTick(() => {
      initSelect2ForVue(document.getElementById('mandatory_additional_concept_id'));
      initSelect2ForVue(document.getElementById('optional_additional_concept_ids'));
    });
  }
}
</script>
