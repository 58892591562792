<template>
    <div>
      <div v-if="logs">
        <table class="table table-bordered background-white">
          <thead>
            <tr>
              <th>{{ tableDate }}</th>
              <th>{{ tableEvent }}</th>
              <th>{{ tableSubject }}</th>
              <th>{{ tableDeliveryStatus }}</th>
            </tr>
          </thead>
          <tbody>
            <email-row v-for="(email) in emails" :email="email" />
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>{{ NotRegisters }}</p>
      </div>
      <infinite-loading @infinite="loadLogs">
        <template v-slot:no-more>
          <div v-if="logs">
            <p class="pb-3 m-0">{{ NotMore }}</p>
          </div>
          <div v-else>&nbsp;</div>
        </template>
        <template v-slot:no-results></template>
        <template v-slot:spinner><b-spinner variant="success" label="Cargando..."></b-spinner></template>
      </infinite-loading>
    </div>
</template>

<script>
import EmailRow from './email-row.vue';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: { EmailRow, InfiniteLoading },
  props: {
    folderId: {
      type: Number
    },
    historyLogs: {
      type: Number
    },
    tableDate: {
      type: String
    },
    tableDeliveryStatus: {
      type: String
    },
    tableEvent: {
      type: String
    },
    tableSubject: {
      type: String
    },
    NotRegisters: {
      type: String
    },
    NotMore: {
      type: String
    }
  },
  data() {
    return {
      page: 0,
      emails: [],
      logs: true,
      is_loading: false
    }
  },
  methods: {
    loadLogs($state) {
      this.is_loading = true;
      if (this.page == null) {
        return;
      }

      return fetch(this.logs_url())
        .then(blob => blob.json())
        .then(({ next_page, emails }) => {
           if (emails.length === 0 && next_page > this.historyLogs) {
            this.page = null;
            $state.complete();
            if (this.emails.length === 0){
              this.logs = false;
            }
            return;
          }

          this.page = next_page;
          this.emails = this.emails.concat(emails);
        })
        .finally(() => {
          this.is_loading = false;
          if (this.page == null) {
            $state.complete();
          } else {
            $state.loaded();
          }
        })
    },
    logs_url() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.append('page', this.page);
      return `/folders/${this.folderId}/email_logs.json?${urlParams.toString()}`;
  },
  }
}
</script>
<style scoped>
</style>
