import { render, staticRenderFns } from "./commission-schemes-role.vue?vue&type=template&id=baaeb358&scoped=true&"
import script from "./commission-schemes-role.vue?vue&type=script&lang=js&"
export * from "./commission-schemes-role.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baaeb358",
  null
  
)

export default component.exports