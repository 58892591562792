<template>
  <div>
    <client-card v-for="(client) in clients" :key="client.id" :client="client" />
    <infinite-loading @infinite="loadClients" v-if="show_infinite_loading">
      <template v-slot:no-more>&nbsp;</template>
      <template v-slot:no-results>&nbsp;</template>
      <template v-slot:spinner>
        <b-spinner variant="success" label="Cargando..."></b-spinner>
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import ClientCard from './client-card.vue';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: { ClientCard, InfiniteLoading  },
  props: ['client_status_id'],
  data() {
    return {
      page: 1,
      clients: [],
      is_loading: false,
      show_infinite_loading: true
    }
  },
  methods: {
    loadClients($state) {
      this.is_loading = true;
      if (this.page == null) {
        return;
      }

      return fetch(this.client_url())
          .then(blob => blob.json())
          .then(({ next_page, clients }) => {
            this.page = next_page;
            this.clients = this.clients.concat(clients);
          })
          .finally(() => {
            this.is_loading = false;
            if (this.page == null) {
              $state.complete();
            } else {
              $state.loaded();
            }
            this.show_infinite_loading = this.clients.length > 0;
          })
    },
    client_url() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const selectedUserId = urlParams.get('selected_user_id');
      urlParams.append('page', this.page);
      urlParams.append('q[client_status_id_eq]', this.client_status_id);
      if (selectedUserId) {
        urlParams.append('selected_user_id', selectedUserId);
      }
      return `/clients/pipeline.json?${urlParams.toString()}`;
    },
  }
}
</script>
