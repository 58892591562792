<template>
  <tr>
    <td>
      {{ number }}
    </td>
    <td>
        <input type="date" :value="date" v-on:change="changeDate" placeholder="YYYY--MM-DD" name="date" class="form-control" :class="valid_date ? '' : 'is-invalid'"/>
        <small class="text-danger" v-if="!valid_date">
          Fecha requerida
        </small>
    </td>
    <td>

        <input class="form-control" :value="formatMoney(capital, currencyTag, currencyCode)" :class="valid_capital ? '' : 'is-invalid'" v-on:change="changeAmount" v-currency="{
                locale: currencyTag,
                currency: currencyCode,
                valueAsInteger: false,
                distractionFree: true,
                precision: 2,
                autoDecimalMode: false,
                valueRange: { min: 0 },
                allowNegative: false
              }"/>

      <small class="text-danger" v-if="!valid_capital">
        El valor debe ser mayor o igual a 1
      </small>

    </td>
    <td v-if="showAdditionalConcepts">
      <input
          class="form-control"
          :value="formatMoney(extra_amount, currencyTag, currencyCode)"
          :class="valid_capital ? '' : 'is-invalid'"
          v-on:change="changeExtraAmount"
          v-currency="{
            locale: currencyTag,
            currency: currencyCode,
            valueAsInteger: false,
            distractionFree: true,
            precision: 2,
            autoDecimalMode: false,
            valueRange: { min: 0 },
            allowNegative: false
          }"
      />
    </td>
    <td>
      <input v-if="this.isEditable" class="form-control" :value="formatMoney(interest, currencyTag, currencyCode)" :class="valid_capital ? '' : 'is-invalid'" v-on:change="changeInterest" v-currency="{
                locale: currencyTag,
                currency: currencyCode,
                valueAsInteger: false,
                distractionFree: true,
                precision: 2,
                autoDecimalMode: false,
                valueRange: { min: 0 },
                allowNegative: false
              }"/> <span v-else>{{ formatMoney(interest, currencyTag, currencyCode) }}</span>
    </td>
    <td>
      {{ formatMoney(total, currencyTag, currencyCode) }}
    </td>
    <td>
      {{ formatMoney(debt, currencyTag, currencyCode) }}
    </td>
    <td>
      <button type="button" class="button-icon" v-on:click="deleteInstallment(index)" title="Eliminar letra">
        <i class="fa fa-trash-o icon-red"></i>
      </button>
    </td>
  </tr>
</template>
<script>
import Utilities from '../../mixins/utilities';

export default {
  props: [
    'id',
    'number',
    'date',
    'capital',
    'extra_amount',
    'interest',
    'total',
    'debt',
    'idx',
    'valid_date',
    'valid_capital',
    'isEditable',
    'currencySymbol',
    'currencyCode',
    'currencyTag',
    'showAdditionalConcepts',
  ],
  mixins: [Utilities],
  data() {
    return {
      amountCapital: this.capital,
      amountInterest: this.interest,
      extraAmount: this.extra_amount,
      newDate: this.date,
      index: this.idx
    }
  },
  methods: {
    deleteInstallment: function(index) {
      this.$emit('removeInstallment', this.index );
    },
    changeAmount: function(event) {
      this.amountCapital = this.removeFormatAmount(event.target.value);
      this.$emit('installmentChanged', {
        interest: this.amountInterest,
        capital: this.amountCapital,
        extra_amount: this.extraAmount,
        date: this.newDate,
        index: this.index
      });
    },
    changeInterest: function(event) {
      this.amountInterest = this.removeFormatAmount(event.target.value);
      this.$emit('installmentChanged', {
        interest: this.amountInterest,
        capital: this.amountCapital,
        extra_amount: this.extraAmount,
        date: this.newDate,
        index: this.index
      });
    },
    changeExtraAmount: function (event) {
      console.log(event.target.value);
      this.extraAmount = this.removeFormatAmount(event.target.value);
      this.$emit('installmentChanged', {
        interest: this.amountInterest,
        capital: this.amountCapital,
        extra_amount: this.extraAmount,
        date: this.newDate,
        index: this.index
      });
    },
    changeDate: function(event) {
      const date = event.target.value;
      this.newDate = date;
      this.$emit('installmentChanged', {
        interest: this.amountInterest,
        capital: this.amountCapital,
        extra_amount: this.extraAmount,
        date: this.newDate,
        index: this.index
      });
    },
    removeFormatAmount: function (value) {
      return Number(value.replace(this.currencySymbol, '').replace(',', ''));
    }
  },
};
</script>
