<template>
  <section class="w-100" v-if="!is_payment_complement">
    <div class="row">
      <div class="col-md-1 text-center py-3">
        <img v-bind:src="folders_gray" class="img-fluid" alt="Icono conceptos">
      </div>
      <div class="col-md-11">
        <div class="d-flex justify-content-between py-2">
          <h3 class="vertical-align m-0">Conceptos</h3>
          <button v-if="billing_concepts.filter((concept) => concept._destroy !== 1).length < 1" type="button" title="Agregar" class="btn btn-info" @click="addBillingConcept">+</button>
        </div>
        <div class="row mb-3">
          <div class="col-4 pr-0">
            <hr class="marker-bar-green">
          </div>
          <div class="col-8 pl-0">
            <hr class="marker-bar-gray">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-container">
          <table class="table">
            <thead>
            <tr>
              <th>Nombre interno <span>*</span></th>
              <th>Cantidad</th>
              <th>Claves</th>
              <th>Descripción <span>*</span></th>
              <th>Descuento</th>
              <th>Objeto impuesto</th>
              <th>Impuestos</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in billing_concepts" :key="index" v-show="item._destroy !== 1">
              <td>
                <input type="hidden" v-model="item.id" :name="'billing_key[billing_concepts_attributes]['+index+'][id]'">
                <input type="text" v-model="item.internal_name" :name="'billing_key[billing_concepts_attributes]['+index+'][internal_name]'" class="form-control">
              </td>
              <td>
                <input type="number" v-model="item.quantity" :name="'billing_key[billing_concepts_attributes]['+index+'][quantity]'" class="form-control text-right">
              </td>
              <td>
                <label>Clave unidad</label>
                <input type="text" v-model="item.unit_key" :name="'billing_key[billing_concepts_attributes]['+index+'][unit_key]'" class="form-control">
                <label class="mt-1">Clave producto ó servicio <span>*</span></label> 
                <input type="text" v-model="item.product_key" :name="'billing_key[billing_concepts_attributes]['+index+'][product_key]'" class="form-control">
              </td>
              <td>
                <textarea v-model="item.description" :name="'billing_key[billing_concepts_attributes]['+index+'][description]'" class="form-control"></textarea>
              </td>
              <td>
                <input type="number" v-model="item.discount" :name="'billing_key[billing_concepts_attributes]['+index+'][discount]'" class="form-control text-right">
              </td>
              <td>
                <select v-model="item.taxability" :name="'billing_key[billing_concepts_attributes]['+index+'][taxability]'" class="select_taxability">
                  <option v-for="(taxability) in sat_catalog_taxability" :key="taxability.type" v-bind:value="taxability.type" :selected="taxability.type === item.taxability">
                    {{ taxability.name }}
                  </option>
                </select>
              </td>
              <td>
                <div v-for="(tax_type, index_child) in item.taxes" :key="index+'_'+index_child+'_'+tax_type.type" class="mb-2">
                  <b-form-checkbox v-model="tax_type.selected" :name="'billing_key[billing_concepts_attributes]['+index+'][taxes]['+index_child+'][selected]'">
                    {{ tax_type.type }} <span v-if="tax_type.withholding">(Retención)</span>
                  </b-form-checkbox>
                  <input type="hidden" v-model="tax_type.type" :name="'billing_key[billing_concepts_attributes]['+index+'][taxes]['+index_child+'][type]'">
                  <input type="hidden" v-model="tax_type.withholding" :name="'billing_key[billing_concepts_attributes]['+index+'][taxes]['+index_child+'][withholding]'">
                  <select v-if="tax_type.selected" v-model="tax_type.rate" :required="tax_type.selected" :name="'billing_key[billing_concepts_attributes]['+index+'][taxes]['+index_child+'][rate]'" class="form-control">
                    <option v-for="value in tax_type.values" :key="index+'_'+index_child+'_'+tax_type.type+'_'+value.rate" :value="value.rate" :selected="value.rate === tax_type.rate">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </td>
              <td class="text-right">
                <input v-model="item._destroy" type="hidden" :name="'billing_key[billing_concepts_attributes]['+index+'][_destroy]'">
                  <a @click="click_delete(item.id, index)" data-toggle="" data-confirm="¿Estás seguro? Se eliminará las configuraciones asociadas con este registro." href="javascript:void(0);" class="table-link existing" title="Eliminar">
                    <i class="fa fa-trash"></i>
                  </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import folders_gray from 'images/icons/folders_gray.png';

export default {
  name: 'billing-concept',
  props: { params: Object },
  data() {
    return {
      folders_gray,
      billing_concepts: [],
      sat_catalog_taxability: [],
      sat_catalog_tax_types: [],
      rates: [],
      invoice_type: '',
      is_payment_complement: false
    };
  },
  methods: {
    addBillingConcept() {
      const taxes = this.sat_catalog_tax_types.map((tax_type) =>  ({...tax_type}));
      this.billing_concepts.push({taxes});
    },
    jsonToArray(jsonValue) {
      return Object.keys(jsonValue).map((key) => ({ type: key, name: jsonValue[key] }));
    },
    getNameTaxType(type) {
      return this.sat_catalog_tax_types.find((tax_type) => tax_type.type === type).name;
    },
    click_delete(id, index) {
      let btn_confirm_delete = document.querySelector('.commit');
      if(btn_confirm_delete){
        btn_confirm_delete.onclick =  this.assign_removed(id, index);
      }
    },
    assign_removed(id, index) {
      this.billing_concepts[index]._destroy = 1;
      this.$forceUpdate();
    },
  },
  mounted() {
    if (this.params?.taxability) {
      this.sat_catalog_taxability = this.jsonToArray(this.params.taxability);
    }
    if (this.params?.tax_types) {
      this.sat_catalog_tax_types = this.params.tax_types;
    }
    if (this.params?.billing_concepts) {
      const sat_catalog_tax_types = this.sat_catalog_tax_types;
      const billing_concepts = this.params.billing_concepts;

      this.billing_concepts = billing_concepts.map(concept => {
        concept.taxes = concept.taxes.map(tax => {
          const tax_type = sat_catalog_tax_types.find(sat_tax_type => (
              sat_tax_type.type === tax.type && sat_tax_type.withholding.toString() === tax.withholding
          ));
          tax.values = tax_type.values;
          tax.selected = (tax.selected === 'true');
          tax.withholding = (tax.withholding === 'true');
          return tax;
        });
        return concept;
      });
    }
    if (this.params?.invoice_type) {
      this.is_payment_complement = (this.params.invoice_type === 'P');
    }
  },
  updated() {
    document.querySelectorAll(".select_taxability").forEach(function(select_two) {
      initSelect2(select_two);
    });
    $('#billing_key_invoice_type').on('change', event => {
      const value = event.target.value;
      this.is_payment_complement = (value === 'P');
    });
  }
}
</script>

<style scoped></style>
