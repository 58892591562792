<template>
  <section>
    <div class="container">
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h3 class="text-left vertical-align m-0 pb-3">Por Pagar</h3>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4 pr-0">
          <hr class="marker-bar-green" />
        </div>
        <div class="col-8 pl-0">
          <hr class="marker-bar-gray" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="pb-3">
            <b-form-checkbox v-model="editable_interest" name="check-button" switch size="lg" v-on:change="changeCanEditInterest">
              Editar actualización
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-md-3">
          <label>Monto de enganche</label>
          <div class="font-bold">
            {{ formatMoney(totalDownPayment, currencyTag, currencyCode) }}
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-3" v-if="lastPayment > 0">
          <label>Pago a Contra entrega</label>
          <div class="font-bold">
            {{ formatMoney(lastPayment, currencyTag, currencyCode) }}
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-3">
          <label>Monto a financiar</label>
          <div class="font-bold">
            {{ formatMoney(totalSale, currencyTag, currencyCode) }}
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-3" v-if="showAdditionalConcepts">
          <label>Monto de servicios adicionales</label>
          <div class="font-bold">
            {{ formatMoney(totalAdditionalConcepts, currencyTag, currencyCode) }}
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-3">
          <label>Total actualización</label>
          <div class="font-bold">
            {{ formatMoney(totalInterest, currencyTag, currencyCode) }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="actions text-right pt-3">
            <button
              class="btn btn-info btn-sm"
              @click="addCustomInstallment"
              :disabled="has_no_custom_payments"
              type="button"
            >
              Agregar nueva letra <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="has_no_custom_payments">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <h6 class="text-center m-0">Existen letras con pagos activos, para hacer uso de este módulo es necesario que el expediente no cuente con pagos de financimiento activos.</h6>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-12" v-if="is_payments_limit && Number(capital_residue) > 0 && add_item">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <h6 class="text-center m-0">Has alcanzado la cantidad máxima de cuotas a crear y el saldo a capital aun es mayor a 0. Por favor realiza los ajustes correspondientes.</h6>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-12" v-else-if="is_payments_limit && Number(capital_residue) == 0 && add_item">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <h6 class="text-center m-0">Has alcanzado la cantidad máxima de cuotas a crear.</h6>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <form action="POST" class="sticky-top background-white pt-3" @submit.prevent="handleSubmit">
            <table-installments @updateInstallments="processInstallments"
                                @removeInstallment="removeInstallment"
                                :installments="installments"
                                :isEditable="this.editable_interest"
                                :currency-symbol="currencySymbol"
                                :currency-code="currencyCode"
                                :currency-tag="currencyTag"
                                :show-additional-concepts="showAdditionalConcepts"
                                ></table-installments>
            <div class="container">
                <div class="actions text-right py-3">
                  <a :href="urlCancel" class="btn btn-secondary">Cancelar</a>
                  <button type="submit"
                          :disabled="disabledSaveBtn"
                          class="btn btn-primary">
                    Guardar
                  </button>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Utilities from '../../mixins/utilities';
import axios from 'axios';
export default {
  props: ["urlProcessInstallments",
          "urlCancel",
          "urlSaveInstallments",
          "urlChangeCanEditInterest",
          "urlSaveStatusInstallments",
          "urlExportCustomInstallments",
          "currencySymbol",
          "currencyCode",
          "currencyTag"],
  mixins: [Utilities],
  data() {
    return {
      totalSale: 0,
      lastPayment: 0,
      totalDownPayment: 0,
      totalInterest: 0,
      totalAdditionalConcepts: 0,
      installments: [],
      is_payments_limit: false,
      editable_interest: false,
      capital_residue: 0,
      additional_concepts_residue: 0,
      add_item: false,
      has_custom_payments: false,
      has_no_custom_payments: false,
      isEditable: false
    };
  },
  methods: {
    addCustomInstallment: async function (event) {
      const button = event.currentTarget;
      button.disabled = true;
      const installments = this.getInstallments();
      await this.requestProcessCustomInstallments(true, installments, false, this.editable_interest);
      button.disabled = false;
    },
    handleSubmit: async function () {
      const installments = this.getInstallments();
      if (!this.validateForm(installments)) return;

      await Swal.fire({
        title: '¿Seguro que deseas guardar las cuotas personalizadas?',
        text:
          'Recuerda que al confirmar esta acción la fórmula de cálculo dejará de ser la de pagos iguales y perderás acceso a los siguientes módulos: Abonos a capital, reestructuras de saldo, cambios de fechas y prórrogas de pagos.',
        type: 'warning',
        showCancelButton: true,
        closeOnCancel: true,
        allowOutsideClick: false,
        cancelButtonText: 'No, Cancelar.',
        confirmButtonText: 'Sí, Continuar.',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-2',
        },
        buttonsStyling: false,
      }).then(isConfirm => {
        const { value = false } = isConfirm;
        if (value) { this.saveInstallments(installments, this.editable_interest); }
      });
    },
    validateForm: function(installments = []){
      let valid_form = true;
      installments.forEach((installment, idx) => {
        if(installment.date === null){
          valid_form = false;
          this.installments[idx].valid_date = false;
        }

        if(Number(installment.capital) === 0){
          valid_form = false;
          this.installments[idx].valid_capital = false;
        }
      });
      return valid_form;
    },
    changeCanEditInterest: async function(event){
      const status = this.editable_interest
      await fetch(this.urlChangeCanEditInterest,
          {
            method: "PATCH",
            body: JSON.stringify({ edit_interest_status: status }),
            headers: {
              'X-CSRF-Token': Rails.csrfToken(),
              'Content-Type': 'application/json',
            },
          }
        );
      const installments = this.getInstallments();
      await this.requestProcessCustomInstallments(false, installments, false, this.editable_interest);
    },
    saveInstallments: async function(installments = [], editable_interest) {
      try {
        showHideLoading(true);
        const ctx = this;
        const response = await fetch(this.urlSaveInstallments,
          {
            method: "POST",
            body: JSON.stringify({ installments: installments, editable_interest: editable_interest }),
            headers: {
              'X-CSRF-Token': Rails.csrfToken(),
              'Content-Type': 'application/json',
            },
          }
        );
        if (!response.ok) throw response.message;
        const result = await response.json();
        showHideLoading(false);
        Swal.fire({
          title: '¡ÉXITO!',
          text: result.message,
          type: 'success',
          confirmButtonText: 'Continuar',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        }).then(function() {
          ctx.requestProcessCustomInstallments(false, [], true, this.editable_interest)
        });

      } catch (error) {
        showHideLoading(false);
        console.error(error);
        Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Hubo un error al procesar la información, por favor inténtalo de nuevo.',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButton: false,
        cancelButton: true,
        buttonsStyling: false,
      });
      }

    },
    processInstallments: function (installment){
      const installments = this.getInstallments();
      if (!installments[installment.index]) return;
      installments[installment.index].capital = installment.capital;
      installments[installment.index].extra_amount = installment.extra_amount;
      installments[installment.index].date = installment.date;
      installments[installment.index].interest = installment.interest;
      this.requestProcessCustomInstallments(false, installments, false, this.editable_interest);
    },
    requestProcessCustomInstallments: async function(add_item = false, installments = [], only_load = false, editable_interest = false) {
      try {
        showHideLoading(true);
        this.installments = [];
        const response = await fetch(this.urlProcessInstallments,
          {
            method: "PATCH",
            body: JSON.stringify({ installments: installments, add_item, only_load, editable_interest }),
            headers: {
              'X-CSRF-Token': Rails.csrfToken(),
              'Content-Type': 'application/json',
            },
          }
        );
        const result = await response.json();
        this.installments = result.installments;
        this.is_payments_limit =  result.is_payments_limit;
        this.capital_residue = result.capital_residue;
        this.additional_concepts_residue = result.additional_concepts_residue;
        this.add_item = result.add_item;
        this.has_custom_payments = result.has_custom_payments;
        this.has_no_custom_payments = result.has_no_custom_payments;
        this.totalSale = result.totalSale;
        this.lastPayment = result.last_payment;
        this.totalDownPayment = result.total_down_payment;
        this.totalInterest = result.totalInterest;
        this.editable_interest = result.editable_interest;
        this.totalAdditionalConcepts = result.total_additional_concepts;
      } catch (error) {
        console.error(error)
      }
      finally{
        showHideLoading(false);
      }
    },
    removeInstallment: function(index){
      const installments = this.getInstallments().filter(function(el, idx) { return idx  != index });
      this.requestProcessCustomInstallments(false, installments, false, this.editable_interest);
    },
    getInstallments: function (){
      const installments = this.installments.map(function(item) {
        return {
          id: item.id,
          date: item.date,
          capital: item.capital,
          extra_amount: item.extra_amount,
          interest: item.interest
        }
      });
      return installments;
    }

  },
  created: async function () {
    this.requestProcessCustomInstallments(false, [], true, this.editable_interest);
  },
  computed: {
    showAdditionalConcepts() {
      return this.totalAdditionalConcepts > 0;
    },
    disabledSaveBtn() {
      return (
          this.installments.length === 0 ||
          this.has_no_custom_payments ||
          Number(this.capital_residue) !== 0 ||
          Number(this.additional_concepts_residue) !== 0
      );
    }
  }
};
</script>
